require('corejs-typeahead/dist/typeahead.jquery.min');
let Bloodhound = require('corejs-typeahead/dist/bloodhound.min');

function startTypeahead(typeaheadInputSelector) {
    // pass the data-select-typeahead types to display, eg.
    // 'data-select-typeahead': 'city, municipalities' only shows cities and municipalities.
    let allowedTypes = typeaheadInputSelector.data('selectTypeahead').split(/[\s]*,[\s]*/);

    /**
     * Setup Bloodhound for typeahead
     */
    let typeaheadSource = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/suggest?query=%QUERY&type=' + allowedTypes,
            wildcard: '%QUERY',
            rateLimitWait: 100,
        },
    });

    let showNrResults = true;
    if (typeaheadInputSelector.attr('data-suggest-hide-count')) {
        showNrResults = false;
    }
    // If this is true we can overwrite .val() of selected city
    let isAllowedToOverWriteSelectedValue = true;

    // Remove the attribute so they don't have typeahead added again if the script is ran a second time.
    typeaheadInputSelector.removeAttr('data-select-typeahead');

    /**
     * Enable typeahead
     */
    typeaheadInputSelector.typeahead(null, {
        display: 'name',
        highlight: true,
        source: typeaheadSource,
        autoselect: true,
        templates: {
            notFound: [
                '<div class="suggestion-block">Geen locatie gevonden.</div>'
            ],
            footer: function (query) {
                return '<div class="suggestion-block footer">Gezocht naar: ' + query.query + '</div>';
            },
            suggestion: function (data) {
                if (true === showNrResults && data.nrResults > 0) {
                    return '<div class="suggestion-block suggestion" data-id="' + data.id + '" data-type="' + data.type + '" data-name="' + data.name + '"><span class="location">' + data.name + '</span><br /><span class="type">' + data.typeTranslated + '</span><span class="number">' + data.nrResults + '</span>';
                } else {
                    return '<div class="suggestion-block suggestion" data-id="' + data.id + '" data-type="' + data.type + '" data-name="' + data.name + '"><span class="location">' + data.name + '</span><br /><span class="type">' + data.typeTranslated + '</span>';
                }
            },
        },
    }).bind('typeahead:select', function (ev, suggestion) {
        let $form = $(this).closest('form');
        updateSearchField($form, suggestion);
        isAllowedToOverWriteSelectedValue = false;
        if (undefined === $form.attr('data-prevent-submit-on-typeahead-select')) {
            $form.submit();
        }
    }).bind('typeahead:render', function (event, suggestions, async, dataSetName) {
        // Check if we are allowed to update and if there are results
        // If so always set first result as selected value.
        if (true === isAllowedToOverWriteSelectedValue && suggestions.length > 0) {
            const firstSuggestion = suggestions.shift();
            // When attribute `data-typeahead-only-update-on-same-name-match` is set the suggestion name starts with input name.
            if (undefined !== typeaheadInputSelector.attr('data-typeahead-only-update-on-same-name-match')
                && false === firstSuggestion.name.toUpperCase().startsWith(typeaheadInputSelector.val().toUpperCase())) {
                return;
            }
            updateSearchField($(this).closest('form'), firstSuggestion);
        }
    }).bind('typeahead:asyncrequest', function () {
        // disable the button
        $(this).closest('form').find('button').attr('disabled', true);
        // Clear selected data.
        if (true === isAllowedToOverWriteSelectedValue) {
            updateSearchField($(this).closest('form'), {id: null, name: null, type: null});
        }
    }).bind('typeahead:asyncreceive typeahead:asynccancel', function () {
        // enable the button
        $(this).closest('form').find('button').attr('disabled', false);
    });

    /**
     * updateSearchField
     */
    function updateSearchField($form, suggestion) {
        if (suggestion === null) {
            return;
        }

        let $suggestId = $form.find('#suggestId');
        let $suggestType = $form.find('#suggestType');
        let $locationName = $form.find('data-select-typeahead');
        let $newsletterSuggestId = $form.find('[data-news-letter-suggest-id]');
        let $newsletterSuggestType = $form.find('[data-news-letter-suggest-type]');

        $suggestId.val(suggestion.id).trigger('change');
        $suggestType.val(suggestion.type).trigger('change');
        $locationName.val(suggestion.name).trigger('change');
        $newsletterSuggestId.val(suggestion.id);
        $newsletterSuggestType.val(suggestion.type);
    }

    typeaheadInputSelector.focus();
}

$(document).ready(() => {
    $('body').on('click focus', 'input[data-select-typeahead]', function () {
        startTypeahead($(this));
    });
});
