require('./vendor/bootstrap');
require('../../common/js/components/typeahead');
require('../../common/js/components/social-connect');
require('../../common/js/components/modal');
require('../../common/js/components/nav-search');
require('../../common/js/templates/newsletter_modal/modal');
require('./custom');
const AjaxForm = require('public/js/includes/modules/ajax-form');

$(document).ready(function () {
    let ajaxForm = new AjaxForm();
    ajaxForm.bindEventListeners();
});
