// requires bootstrap modal.

$(document).ready(function ($) {
    const getTitle = 'data-get-title-modal';
    const setTitle = 'data-set-title-modal';
    const setContent = 'data-select-fill';

    $('.modal').on('show.bs.modal', function (event) {
        // hide backtotop
        $('#backtotop').hide();

        // get button
        const submitButton = $(event.relatedTarget);

        // get url
        const url = submitButton.data('load-url');

        // get method
        let method = submitButton.data('load-method') ?? 'POST'; // options: 'GET' or 'POST' . default is 'POST'

        // get (optional) form div
        let postForm = submitButton.data('post-form');

        // get modal
        const $modal = $(this);

        // get loader div
        const $loader = $modal.find('.modal-loader');

        const $content = $modal.find('[' + setContent + ']');

        // fetch form as data
        let data = '';
        if (postForm) {
            data = $(postForm).serialize();
        }

        // only load if not already loaded or has data
        if (url !== $loader.data('url') || '' !== data) {

            // make sure loader is showing
            $loader.show();
            $content.html('');
            // fetch modal contents

            switch(method) {
                case 'POST':
                    $.post(url, data, function (response) {
                        responseData(response, $modal, $loader);
                    }).then(() => {
                        $loader.data('url', url);
                        $loader.hide();
                    });
                    break;
                default:
                    $.get(url, data, function (response) {
                        responseData(response, $modal, $loader);
                    }).then(() => {
                        $loader.data('url', url);
                        $loader.hide();
                    });
            }
        }
    });

    function responseData(response, $modal, $loader) {
        // add title
        const $response = $(response);
        const $titleLocation = $response.find('[' + getTitle + ']');

        if (0 < $titleLocation.length) {
            const getTitleString = $titleLocation.attr(getTitle);
            $modal.find('[' + setTitle + ']').html(getTitleString);
        }

        // add content
        $modal.find('[' + setContent + ']').html(response);
    }
});
