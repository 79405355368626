import * as constants from './constants';
const adjustMinMaxPrice = require('../../components/adjust-min-max-price');
import Cookies from 'js-cookie';

let modalBehaviour, modal, newsletterModalTypeCookie;

var jq = jQuery.noConflict();

// Controls the modal on the search page.
// Be default it shows the modal after 10s.
// If the search page is reached from a rental page, the modal is shown instantly.
// If the modal has already been shown, don't display it again for a day.
// @see https://www.cookiebot.com/en/developer/#:~:text=already%20been%20submitted.-,CookiebotOnAccept,-The%20event%20is
window.addEventListener('CookiebotOnAccept', executeModal);
window.addEventListener('CookiebotOnDecline', executeModal);

// run when back/forward caching is used
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    executeModal();
  }
})

// run on page load
jq(document).ready(executeModal);

export function executeModal() {
  if (typeof Cookiebot === "undefined" || !Cookiebot.hasResponse) return;

  init();

  if (Cookies.get(constants.openInstantly)) {
    showNewsletterModal(false);
    Cookies.remove(constants.openInstantly);
    return;
  }

  newsletterModalTypeCookie = Cookies.get(constants.newsletterModalProgress);
  if (newsletterModalTypeCookie === constants.modalShown) return;

  setCookie();
}

function setCookie() {
  let behaviours = modalBehaviour.data('behaviour');

  if (false === Array.isArray(behaviours)) {
    behaviours = [behaviours];
  }

  switch (true) {
    case behaviours.includes(constants.setSessionDefault):
      Cookies.set(constants.newsletterModalProgress, constants.showWithDelay, { expires: 1, path: '/', secure: true, sameSite: 'strict' });
      break;
    case (newsletterModalTypeCookie === constants.showInstantly || behaviours.length === 1)
    && behaviours.includes(constants.showImmediately):
      showNewsletterModal();
      break;
    case behaviours.includes(constants.primeShowImmediately):
      Cookies.set(constants.newsletterModalProgress, constants.showInstantly, { expires: 1, path: '/', secure: true, sameSite: 'strict' });
      break;
    case behaviours.includes(constants.showAfterDelay):
      let delay = modalBehaviour.data('delay');
      if (delay === undefined) {
        delay = constants.timeBeforeShowingModal;
      }
      window.setTimeout(function(){showNewsletterModal()}, delay);
      break;
  }
}

function showNewsletterModal(saveCookie=true) {
  if (modal.find('form').length === 0) return;
  if (newsletterModalTypeCookie === constants.showNotYet) return;

  if(true === modalBehaviour.data('user')) {
    jq.ajax(
        '/mail-subscription/register-user-seen-modal',
        {
          cache: false,
          dataType: 'html'
        }
    )
  }

  modal.modal('show');
  if (true === saveCookie) {
    Cookies.set(constants.newsletterModalProgress, constants.modalShown, { expires: 1, path: '/', secure: true, sameSite: 'strict' });
  }
}

function init() {
  jq('[data-minimum-price='.concat('"new"]')).change(function () {
    adjustMinMaxPrice('new', true);
  });
  jq('[data-maximum-price='.concat('"new"]')).change(function () {
    adjustMinMaxPrice('new', false);
  });

  modalBehaviour = jq('#newsletter-modal-behaviour').first();
  modal = jq('#newsletter-modal');
}
