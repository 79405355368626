// Constants to be used by the newsletter modal JS scripts.
// See App\Entity\MailSubscription for the constants used by the PHP code.
export const showWithDelay = '1';
export const showInstantly = '2';
export const modalShown = '3';
export const showNotYet = '4';

// See App\Form\NewsletterModalType for the constants used by the PHP code.
export const setSessionDefault = 0;
export const showAfterDelay = 1;
export const primeShowImmediately = 2;
export const showImmediately = 3;

export const newsletterModalProgress = 'newsletter-modal-progress';
export const timeBeforeShowingModal = 10000;

export const openInstantly = 'open-modal-instantly';
