const detectTypo = require('../includes/levenshtein-typo');

$(document).ready(function ($) {
  let body = $('body');
  let suggestions = [];

  body.one('focus', '[data-check-domain]', function () {
    $.ajax(
      '/public/api/fetch-email-typo-suggestions',
      {
        cache: false,
        dataType: 'json',
        success: function (JSON) {
          suggestions = JSON.data;
        }
      }
    )
  });

  body.on('keyup', '[data-check-domain]', function () {
    let $suggestionBlock = $(this).closest('[data-suggestion-block]');
    if ($suggestionBlock.length === 0) {
      $suggestionBlock = $(this).closest('.form-group');
    }
    $suggestionBlock.find('.suggestion-message').remove();
    $(this).removeClass('possible-typo');
  });

  body.on('keyup', '[data-check-domain]', delay(function () {
    if (false === Array.isArray(suggestions) || !(0 < suggestions.length)) {
      return;
    }
    let email = $(this).val();
    let domain = email.split('@').pop();
    let suggestionMessage = $(this).data('suggestion-message');

    if (email !== domain) {
      let suggestion = detectTypo(domain, suggestions, 2);

      if (suggestion !== domain) {
        let prefix = email.split('@')[0];
        let suggestText = createSuggestText(suggestionMessage, prefix, suggestion);

        let $suggestionBlock = $(this).closest('[data-suggestion-block]')[0];
        if ($suggestionBlock === undefined) {
          $suggestionBlock = $(this).closest('.form-group')[0];
        }

        $(this).addClass('possible-typo');
        $suggestionBlock.appendChild(suggestText);
      }
    }
  }, 500));

  function delay(callback, ms) {
    let timer = 0;
    return function () {
      let context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  function createSuggestText(suggestionMessage, prefix, suggestion) {
    let updateLink = createUpdateLink(prefix, suggestion);

    // Format the suggestion text
    let suggestText = document.createElement('div');
    suggestText.className = 'suggestion-message';
    suggestText.appendChild(document.createTextNode(suggestionMessage));
    suggestText.appendChild(updateLink);
    suggestText.appendChild(document.createTextNode('?'));

    return suggestText;
  }

  function createUpdateLink(prefix, suggestion) {
    let updateLink = document.createElement('a');

    //Format the suggestion message and add its necessary functionality.
    updateLink.setAttribute('data-suggestion', prefix + '@' + suggestion);
    updateLink.onclick = function () {
      let $suggestionBlock = $(this).closest('[data-suggestion-block]');
      if ($suggestionBlock.length === 0) {
        $suggestionBlock = $(this).closest('.form-group');
      }
      let $field = $suggestionBlock.find('[data-check-domain]');
      $field[0].value = $(this).data('suggestion');
      // trigger change not compatible with addEventListener https://stackoverflow.com/a/64159158
      $field[0].dispatchEvent(new Event('change'));
      $field.removeClass('possible-typo');
      $field.focus();
      $suggestionBlock.find('.suggestion-message').remove();
    };
    updateLink.appendChild(document.createTextNode(prefix + '@'));
    let strongSuggestion = document.createElement('strong');
    strongSuggestion.appendChild(document.createTextNode(suggestion));
    updateLink.appendChild(strongSuggestion);

    return updateLink;
  }
});
