import {load} from 'recaptcha-v3';

const recaptchaCustomEventSelector = 'data-select-recaptcha-custom-event';
const recaptchaPostSuccessEventName = 'reCAPTCHAPostSuccess';
const recaptchaSiteKeyAttributeSelector = 'data-select-recaptcha-site-key';
const recaptchaActionAttributeSelector = 'data-recaptcha-action';
let recaptchaInstance = null;
let defaultPrePrevented = false;

const getRecaptcha = async (siteKey) => {
  if (null === recaptchaInstance) {
    recaptchaInstance = await load(siteKey);
  }

  return recaptchaInstance;
}

const getToken = (siteKey, action) => getRecaptcha(siteKey).then(recaptchaInstance => recaptchaInstance.execute(action));

const setToken = (inputElement, token) => {
  inputElement.value = token;
}

const submit = (form, recaptchaElement) => {
  if (recaptchaElement?.hasAttribute(recaptchaCustomEventSelector)) {
    document.dispatchEvent(
      new CustomEvent(
        '' === recaptchaElement.getAttribute(recaptchaCustomEventSelector) ? recaptchaPostSuccessEventName : recaptchaElement.getAttribute(recaptchaCustomEventSelector),
        {
          detail: {
            target: form,
          }
        }
      )
    );

    return;
  }
  if (!defaultPrePrevented) {
    // @see https://trackjs.com/blog/when-form-submit-is-not-a-function/
    HTMLFormElement.prototype.submit.call(form);
  }
}

// on body submit check if we have recaptcha form then handle recaptcha.
document.body.addEventListener("submit", (event) => {
  // If form has multiple prevent defaults its first comes first serve.
  defaultPrePrevented = event.defaultPrevented;
  event.preventDefault();

  const recaptchaElement = event.target.querySelector(`input[${recaptchaSiteKeyAttributeSelector}]`);
  if (null === recaptchaElement) {
    submit(event.target);

    return;
  }
  const namedItem = recaptchaElement.attributes.getNamedItem(recaptchaSiteKeyAttributeSelector);
  const namedAction = recaptchaElement.attributes.getNamedItem(recaptchaActionAttributeSelector);
  if (false === Boolean(namedItem) || false === Boolean(namedAction)) {
    // Strange we dont find these values as we already found recaptchaSiteKeyAttributeSelector.
    console.error(`We found a recaptcha element without a siteKey: ${namedItem} or action: ${namedAction}`);

    submit(event.target);

    return;
  }
  const recaptchaSiteKey = namedItem.value;
  const recaptchaAction = namedAction.value;
  if (undefined === recaptchaSiteKey || undefined === recaptchaAction) {
    // We should get siteKey and action on recaptcha element.
    console.error(`We found a recaptcha element without a valid siteKey: ${recaptchaSiteKey} or action: ${recaptchaAction}`);

    submit(event.target);

    return;
  }

  // We have a recaptchaElement with recaptcha attribute
  getToken(recaptchaSiteKey, recaptchaAction)
    .then(token => {
      setToken(recaptchaElement, token);
      submit(event.target, recaptchaElement);
    })
    .catch(error => console.error(`Token loader error: `, error))
});

document.addEventListener('DOMContentLoaded', async () => {
  // on load check if we have a data-select-recaptcha-site-key with action so we can trigger recaptcha.
  const recaptchaElements = document.querySelectorAll(`div[${recaptchaSiteKeyAttributeSelector}]`);
  if (undefined !== recaptchaElements) {
    for (const recaptchaElement of recaptchaElements) {
      const namedItem = recaptchaElement.attributes.getNamedItem(recaptchaSiteKeyAttributeSelector);
      if (false === Boolean(namedItem)) {
        continue;
      }
      const recaptchaSiteKey = namedItem.value;
      const namedAction = recaptchaElement.attributes.getNamedItem(recaptchaActionAttributeSelector);
      if (false === Boolean(namedAction)) {
        // we want to make sure to load recaptcha on the page without executing a action.
        await getRecaptcha(recaptchaSiteKey);
      } else {
        // We have a element with action eg: page view action.
        const recaptchaAction = namedAction.value;
        await getToken(recaptchaSiteKey, recaptchaAction)
          .then(token => setToken(recaptchaElement, token))
          .catch(error => console.error(`Token loader error: `, error))
      }
    }
  }
});
