"use strict";

/**
 * # Focus
 *
 * Set auto-focus on form field component after a event on a html component.
 *
 * ## HTML template
 *
 * <a data-focus-event></a>       // The event of this link triggers the focus of a input field
 * <input data-focus-form-field>
 *
 * ## HTML template with data attribute value
 *
 * <a data-focus-event="focus-test"></a>        // The event of this link triggers the focus of a input field
 * <input data-focus-form-field="focus-hello">  // Focus is not triggered here, because the data attribute values between the link and the input field are not similar
 * <input data-focus-form-field>                // Focus is triggered here, because the data attribute value is empty and therefore generic for every trigger from data-focus-event.
 * <input data-focus-form-field="focus-test">   // Focus is not triggered here, because only the first fit candidate is used to focus, which is the input above.
 *
 * If the html component with 'data-focus-form-field' attribute is not a valid form field type,
 * then it will search for the first valid field type within the html component.
 */
export default class Focus {

    /**
     * Constructor
     *
     * @param {string} eventListenerType Event listener type
     * @param {string} formFieldType     Form field type
     */
    constructor(
        eventListenerType = 'click',
        formFieldType = 'input'
    ) {
        this.eventListenerType = eventListenerType;
        this.formFieldType = formFieldType;
        this.dataNameFocusEvent = 'data-focus-event';
        this.dataNameFormField = 'data-focus-form-field';

        this.triggers = document.querySelectorAll('[' + this.dataNameFocusEvent + ']');
        if (0 !== this.triggers.length) {
            this.fields = document.querySelectorAll('[' + this.dataNameFormField + ']');
            this.eventListener();
        }
    }

    /**
     * Set focus
     *
     * @param {object} event Focus event node
     */
    focus(event) {
        const eventValue = event.getAttribute(this.dataNameFocusEvent);
        this.fields.forEach(field => {
            const fieldValue = field.getAttribute(this.dataNameFormField);
            if ('' === fieldValue || eventValue === fieldValue) {
                if (this.formFieldType === field.tagName) {
                    field.focus();
                } else {
                    const fields = field.querySelectorAll(this.formFieldType + ':not([aria-hidden="true"]):not([type="hidden"])');
                    if (0 < fields.length) {
                        fields[0].focus();
                    }
                }

                return true;
            }
        });
    }

    /**
     * Event Listener
     */
    eventListener() {
        this.triggers.forEach(event => event.addEventListener(this.eventListenerType, event => this.focus(event.currentTarget)));
    }
}
