import Toggle from "../../common/js/components/toggle";
import Focus from "./components/focus";

/**
 * function switchModals
 *
 * Allow switching between modals.
 * Also check bootstrap modal for more info: https://www.w3schools.com/bootstrap/bootstrap_ref_js_modal.asp
 *
 * Required html:
 * <a href="#target" data-switch="modal"></a>
 * or:
 * <button data-target="#target" data-switch="modal"></button>
 */
const modalSwitch = modalLink => {
  const target = modalLink.getAttribute('data-target') ?? modalLink.getAttribute('href');

  if (null === target) return;

  const currentModalElement = modalLink.closest('.modal');
  let modalClicked = false;

  modalLink.addEventListener('click', () => {
    modalClicked = true;
    $(currentModalElement).modal('hide');
  });

  $(currentModalElement).on('hidden.bs.modal', () => {
    if (true === modalClicked) $('.modal' + target).modal('show');
    modalClicked = false;
  })
};

/**
 * Handles add/remove to favorite list
 */
// TODO FIXME replace data-favorite with huur ID so remove/add favorite can be done based on active class
function setFavorites() {
  document.querySelectorAll('[data-favorite]').forEach(favorite => {
    favorite.addEventListener('click', event => {
      const clickedFavorite = event.currentTarget,
          url = clickedFavorite.getAttribute('data-favorite');

      clickedFavorite.classList.toggle('liked');

      $.ajax({
        url: url,
        method: 'POST',
      }).done(function (data) {
        if (data['status'] === 'fail') {
          clickedFavorite.classList.toggle('liked');
          console.error('Failed to favorite');
        }

        if (true === clickedFavorite.hasAttribute('data-favorite-reload') && false === clickedFavorite.classList.contains('liked')) location.reload();
      }).fail(function () {
        console.error('Failed to favorite');
      });
    })
  })
}

/**
 * Check if flash message is added on login modal
 */
function renderLoginModal() {
  const $loginModal = $('#login.modal');
  if (
      0 !== $loginModal.length &&
      0 !== $loginModal.find('.alert').length &&
      null === document.querySelector('[data-no-auto-error-modal]')
  ) {
    $loginModal.modal('show');
  }
}

/**
 * Scroll to hash on mobile dropdown.
 */
function scrollToHashInMenu() {
  $('.dropdown-toggle').click(function () {
    document.location.href="#account-dropdown";
  });
}

$(document).ready(function () {
  const toggle = new Toggle();
  toggle.toggleScroll();
  toggle.toggleClick();

  new Focus();

  document.querySelectorAll('[data-switch="modal"]').forEach(modalSwitch);

  setFavorites();
  renderLoginModal();
  scrollToHashInMenu();
});
