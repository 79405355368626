"use strict";

$(document).ready(function () {

  /**
   * Listen to facebook-connect button on click event.
   *
   * When pressing on the button the user will be redirected to the link that
   * is defined in the data attribute `data-href`
   */
  $('body').on('click', '[data-event-social-connect]', function () {
    window.location.href = $(this).data('href');
  });
});
