document.addEventListener('DOMContentLoaded', () => {
  const searchField = document.querySelector('[data-select-navbar-search]');
  const wrapper = document.getElementById('wrapper');

  // If either the search field or wrapper is not found, exit the function early.
  if (!searchField || !wrapper) return;

  const html = document.documentElement;

  const removeSearchActive = () => {
    wrapper.classList.remove('search-active');
    html.classList.remove('no-scroll');
  };

  searchField.addEventListener('focus', () => {
    if (!wrapper.classList.contains('main-menu__mobile--slide-in')) {
      wrapper.classList.add('search-active');
      html.classList.add('no-scroll');
    }
  });

  searchField.addEventListener('blur', removeSearchActive);

  wrapper.addEventListener('click', (event) => {
    if (wrapper.classList.contains('search-active') && !event.target.closest('[data-select-navbar-search]')) {
      searchField.blur();
      removeSearchActive();
    }
  });

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape' && wrapper.classList.contains('search-active')) {
      searchField.blur();
      removeSearchActive();
    }
  });
});
