'use strict';

// On page show we check if event is persisted meaning user did previous page.
window.onpageshow = function (event) {
  if (event.persisted) {
    // If that is the case make sure to restore all submit once.
    $('form').each(function () {
      let $btn = $(this).find('.btn:disabled')
      $btn.html($btn.data('button-html'));
      $btn.attr('disabled', false);
    });
  }
};

$(document).ready(function ($) {
  $('body').on("submit", "form", function (event) {
    submitOnce($(this));
  });
});

/**
 * Prevent form to be submitted more then once.
 *
 * @param {jQuery} $form
 */

function submitOnce($form) {
  // ignore if has attr `data-resubmittable`
  if (true === $form.is('[data-resubmittable]')) {
    return;
  }

  // find submit button
  let $btn = $form.find('input[type="submit"], button[type!="button"]');

  // set spinner
  $btn.css('min-width', $btn.innerWidth());
  $btn.css('min-height', $btn.innerHeight());
  $btn.attr('data-button-html', $btn.html());
  $btn.html('<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>');
  $btn.attr('disabled', 'disabled');
}

/**
 * Export so it can be called from other modules (e.g. comments-modal)
 */
export {submitOnce};
