/**
 * AjaxForm.
 *
 * You can use this to submit a form via ajax and render the response.
 *
 * Usage:
 *  add `data-select-ajax-form-selector`            to the form element.
 *  add `data-select-ajax-form-fill`                     to the element which you want to fill the request data with.
 *
 * Extra note make sure you set action and method, so you post to the correct url.
 *
 * Example:
 * HTML:
 * <form method="POST" action="/ajax/url/" data-select-ajax-form-selector data-select-recaptcha-custom-event>
 *   <div data-select-ajax-form-fill>
 *     <p>This content will be replaced</p>
 *   </div>
 *   <input type="hidden" class="d-none hidden" data-select-recaptcha-site-key="x" id="newsletter_recaptcha_recaptchaToken" name="newsletter[recaptcha][recaptchaToken]" data-recaptcha-action="newsletter_form">
 *   <button type="submit">
 *     Submit
 *    </button>
 * </form>
 *
 * JS:
 * const AjaxForm = require('public/js/includes/modules/AjaxForm');
 *
 * $(document).ready(function () {
 *     let ajaxForm = new AjaxForm();
 *     ajaxForm.bindEventListeners();
 * });
 *
 */
class AjaxForm {
  /**
   * Binds all the events.
   */
  bindEventListeners() {
    this.bindRecaptchaDoneEventListener();
  }

  /**
   * Listen to recaptcha done event.
   */
  bindRecaptchaDoneEventListener() {
    const self = this;

    document.addEventListener('reCAPTCHAPostSuccess', (event) => {
      const {target} = event.detail;
      if (false === target.hasAttribute('data-select-ajax-form-selector')) {
        return;
      }
      fetch(target.getAttribute('action'), {
        method: target.getAttribute('method'),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(new FormData(target)).toString(),
      })
        .then((response) => response.text())
        .then((html) => {
          self.renderContent(target.closest('[data-select-ajax-form-fill]'), html);
        }).catch(error => console.error('Failed to fetch ' + target.getAttribute('action') + ' . Error: ' + error));
    });
  }

  /**
   * Render content to contentElement.
   *
   * @param contentElement
   * @param content
   */
  renderContent(contentElement, content) {
    contentElement.outerHTML = content;
  }
}

module.exports = AjaxForm;
