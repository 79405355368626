"use strict";

import scroll from '../util/scroll.js';

/**
 * # Toggle
 *
 * Allows to toggle a class in a html component after a trigger from a event listener (for example a 'click').
 * Other html component, which act as toggle disablers, will remove the toggle class in the html component and child components after a trigger from a event listener.
 *
 *
 * # Click toggle:
 *
 * ## Javascript:
 * let clicker = new Toggle();
 * clicker.toggleClick({
 *     // add your toggle options here. example:
 *     'toggleClass': 'toggle-active'
 * });
 *
 * ## Basic HTML component with click toggle:
 *
 * <div data-click-toggle></div>
 *
 * ## HTML component with toggle and multiple children.
 * Only children with data attributes values similar to the attribute value of the clickable toggle element or children without attribute values,
 * inherit the toggle class from the clickable toggle element:
 *
 * <div data-click-toggle="first-toggle"         // Clickable toggle element with value "first-toggle"
 *      class="toggle-init"                      // class 'this.classNameInit' is removed directly after first click.
 *      data-toggle-class="active-class"         // The toggle class name is in this case: 'active-class'
 *                                               // If the toggle class name is not defined then default class is chosen
 * ></div>
 * <div data-toggle-child="first-toggle"></div>  // Inherits the toggle class 'active-class' when toggle html component also has this class
 * <div data-toggle-child="second-toggle"></div> // Does NOT inherit the toggle class 'active-class'
 * <div data-toggle-child></div>                 // Inherits the toggle class 'active-class' when toggle html component also has this class
 * <div data-toggle-child="some-id"
 *      data-toggle-child-delay-off="400"        // class 'this.classNameDelayOff' remains 400 milliseconds after the toggle class was removed
 *      data-toggle-child-delay-on="600"         // class 'this.classNameDelayOn' is added after 600 milliseconds when the toggle class was added
 *      class="toggle-init"                      // class 'this.classNameInit' is removed directly after first click.
 * ></div>
 *
 *
 * # Scroll toggle
 *
 * toggle when scroll position from windows top reach a certain height.
 *
 * ## Javascript:
 * let scroller = new Toggle();
 * scroller.toggleScroll({
 *     // add your toggle options here. not required. example:
 *     'toggleClass': 'data-scroll-toggle' // Toggleable class. Class 'data-scroll-toggle' is default.
 *     'toggleAtPixelHeight': 50,          // Toggle when scroll position is 100 pixels removed from window top. 50 pixels is default
 *     'addClassBelow': true,              // Only add toggle class when scroll position is "below" 100 pixels and remove when "above". True is default.
 *     'breakpointUp': 'md',               // Only allow toggle to happen when window width is larger than bootstrap 'md'. Null is default, which means it is disabled.
 *     'breakpointDown': null              // Disabled. Only allow scroll to happen when window width is smaller than bootstrap XX. Null is default, which means it is disabled.
 * });
 *
 * ## Basic HTML component with scroll toggle:
 *
 * <div data-scroll-toggle></div>
 *
 * ## Advanced HTML component with scroll toggle:
 *
 * <div data-scroll-toggle
 *      // add your toggle options here. not required. example:
 *      data-toggle-class="toggle-scroll-active"   // toggleable class. Class 'data-scroll-toggle' is default.
 *      data-scroll-toggle-at-pixel-height="50"    // Toggle when scroll position is 100 pixels removed from window top. 50 pixels is default
 *      data-scroll-toggle-add-class-below="true"  // Only add toggle class when scroll position is "below" 100 pixels and remove when "above". True is default.
 *                                                 // If data-toggle-top is true, than toggle is added when scroll position is above 100 pixels and removed when below.
 *      data-scroll-toggle-breakpoint-up="md"      // Only allow toggle to happen when window width is larger than bootstrap 'md'. Null is default, which means it is disabled.
 * ></div>
 *
 * # Disabler
 *
 * When a disabler element is clicked, all toggle classes are removed
 * when the other elements have a data attribute with the same value as the data attribute from the disabler element.
 *
 * A click event on the disabler element removes the toggle class from all other elements, except the toggle element with value 'hello':
 *
 * <div data-click-toggle="first-toggle"></div>
 * <div data-toggle-child="first-toggle"></div>
 * <div data-scroll-toggle="first-toggle"></div>
 * <div data-scroll-toggle="hello"></div>
 * <div data-toggle-disable="first-toggle"></div>
 */
export default class Toggle {

    /**
     * Constructor
     */
    constructor() {

        // Generic attributes
        this.attributeChild = 'data-toggle-child';
        this.attributeDelayOn = 'data-toggle-child-delay-on';
        this.attributeDelayOff = 'data-toggle-child-delay-off';
        this.attributeDisable = 'data-toggle-disable';
        this.attributeClass = 'data-toggle-class';
        this.noScroll = 'data-toggle-no-scroll';

        // Generic default values
        this.classNameDelayOn = 'delay-on';
        this.classNameDelayOff = 'delay-off';
        this.classNameInit = 'toggle-init';

        // Bootstrap Breakpoints
        this.bootstrapBreakpoints = {
            'xs': 0,
            'sm': 576,
            'md': 768,
            'lg': 992,
            'xl': 1200,
        };

        // Node elements
        this.toggleDisablers = document.querySelectorAll('[' + this.attributeDisable + ']');
        this.toggleChildren = document.querySelectorAll('[' + this.attributeChild + ']');

        // initialize toggleChildren based on attribute properties
        this.propertiesToggleChildren = [];
        this.toggleChildren.forEach(toggleChild => {
            this.propertiesToggleChildren.push({
                'toggleId': toggleChild.getAttribute(this.attributeChild),
                'toggleDelayOn': toggleChild.getAttribute(this.attributeDelayOn),
                'toggleDelayOff': toggleChild.getAttribute(this.attributeDelayOff),
            });
        });
    }

    /**
     * toggle Clicker
     *
     * @param {object} options toggle options
     */
    toggleClick(options = {}) {
        this.eventListenerType = 'click';
        this.options = Object.assign({}, {
            // attributes
            'attribute': 'data-click-toggle',       // add this data attribute to make the click toggle to work
            // default values
            'toggleClass': 'toggle-click-active',   // this is the toggle class
        }, options);

        this.clickToggles = document.querySelectorAll('[' + this.options.attribute + ']');
        this.clickToggles.forEach(toggle => toggle.addEventListener(this.eventListenerType, event => this.toggleClickEvent(event.currentTarget)));
        this.eventListenerDisabler();
    }

    /**
     * Toggle Scroller
     *
     * @param {object} options toggle options
     */
    toggleScroll(options = {}) {
        this.eventListenerType = 'scroll';
        this.options = Object.assign({}, {
            // attributes
            'attribute': 'data-scroll-toggle',                               // data attribute for scroll listener
            'attributeAtPixelHeight': 'data-scroll-toggle-at-pixel-height',  // data attribute to trigger toggle at pixel height
            'attributeAddClassBelow': 'data-scroll-toggle-add-class-below',  // data attribute to add class below or above pixel height
            'attributeBreakPointUp': 'data-scroll-toggle-breakpoint-up',     // data attribute to allow toggle only work above bootstrap breakpoint
            'attributeBreakPointDown': 'data-scroll-toggle-breakpoint-down', // data attribute to allow toggle only work below bootstrap breakpoint
            // default values
            'toggleClass': 'toggle-scroll-active', // default value of toggle class name
            'toggleAtPixelHeight': 50,             // default value when toggle has to occur.
            'addClassBelow': true,                 // default value when class has to be added. True: add toggle class when scroll position is above pixel height. False: add toggle class when scroll position is below pixel height.
            'breakpointUp': null,                  // default value. breakpoint up is disabled.
            'breakpointDown': null,                // default value. breakpoint down is disabled.
        }, options);

        this.scrollToggles = document.querySelectorAll('[' + this.options.attribute + ']');

        // initialize options based on attribute values from toggles
        this.propertiesToggleScrollers = [];
        this.scrollToggles.forEach(toggle => {
            this.propertiesToggleScrollers.push({
                'checker': null,
                'toggleClass': toggle.getAttribute(this.attributeClass) || this.options.toggleClass,
                'toggleAtPixelHeight': toggle.getAttribute(this.options.attributeAtPixelHeight) || this.options.toggleAtPixelHeight,
                'addClassBelow': toggle.getAttribute(this.options.attributeAddClassBelow) || this.options.addClassBelow,
                'breakpointUp': toggle.getAttribute(this.options.attributeBreakPointUp) || this.options.breakpointUp,
                'breakpointDown': toggle.getAttribute(this.options.attributeBreakPointDown) || this.options.breakpointDown,
            });
        });

        this.toggleScrollEvent(); // initialize
        scroll.getScrollableElement().addEventListener('scroll', () => this.toggleScrollEvent(), false);
        this.eventListenerDisabler(); // disabler
    }

    /**
     * Toggles when scrolling
     *
     * @param {boolean} forceDisable Remove toggle class when forced
     */
    toggleScrollEvent(
      forceDisable = false,
    ) {
        const scrollPosY = scroll.scrollYPos();
        const windowWidth = window.innerWidth ?? document.documentElement.clientWidth ?? document.body.clientWidth;
        this.scrollToggles.forEach((toggle, index) => {
            const properties = this.propertiesToggleScrollers[index];
            if (
                null !== properties.breakpointUp &&
                properties.breakpointUp in this.bootstrapBreakpoints &&
                windowWidth < this.bootstrapBreakpoints[properties.breakpointUp]
            ) {
                return;
            }

            if (
                null !== properties.breakpointDown &&
                properties.breakpointDown in this.bootstrapBreakpoints &&
                windowWidth > this.bootstrapBreakpoints[properties.breakpointDown]
            ) {
                return;
            }

            const addClass = false === forceDisable && ((scrollPosY >= properties.toggleAtPixelHeight && true === properties.addClassBelow) || (scrollPosY <= properties.toggleAtPixelHeight && false === properties.addClassBelow));
            if (properties.checker !== addClass) {
                toggle.classList.remove(this.classNameInit);
                toggle.classList.toggle(properties.toggleClass, addClass);
                this.propertiesToggleScrollers[index].checker = addClass;
                this.toggleChild(
                    toggle.getAttribute(this.options.attribute),
                    properties.toggleClass,
                    forceDisable
                );
            }
        })
    }

    /**
     * Toggles class in click element en children.
     *
     * @param {object}  toggle       Toggle node
     * @param {boolean} forceDisable Remove toggle class when forced
     */
      toggleClickEvent(toggle, forceDisable = false) {
        const toggleClassName = toggle.getAttribute(this.attributeClass) || this.options.toggleClass;
        const shouldToggle = !forceDisable || (forceDisable && toggle.classList.contains(toggleClassName));

        if (!shouldToggle) return;

        const html = document.documentElement;
        const slideMenu = document.querySelector('ul.slide-menu');
        const navbar = document.querySelector('[data-navigation="main-menu"]');

        if (this.noScroll) {
            html.classList.toggle('no-scroll');
        }

        this.updateNavbarTransparency(navbar);
        this.updateSlideMenuMargin(navbar, slideMenu);

        toggle.classList.remove(this.classNameInit);
        forceDisable ? toggle.classList.remove(toggleClassName) : toggle.classList.toggle(toggleClassName);

        this.toggleChild(
            toggle.getAttribute(this.options.attribute),
            toggleClassName,
            forceDisable
        );
    }

    updateNavbarTransparency(navbar) {
        if (!navbar.classList.contains('scroll-position-below-transparency')) {
            navbar.classList.add('scroll-position-below-transparency');
        }
    }

    updateSlideMenuMargin(navbar, slideMenu) {
        if (!navbar.classList.contains('main-menu-top-no-trustpilot') && slideMenu) {
            slideMenu.style.marginTop = '42.5px';
        }
    }

    /**
     * Toggle the class in children elements
     *
     * @param {string}  toggleId        Toggle attribute value
     * @param {string}  toggleClassName Toggle class name
     * @param {boolean} forceDisable    Remove toggle class when forced
     */
    toggleChild(
        toggleId,
        toggleClassName,
        forceDisable
    ) {
        this.toggleChildren.forEach((toggleChild, index) => {
            const properties = this.propertiesToggleChildren[index];
            if (null !== properties.toggleId && toggleId === properties.toggleId) {
                toggleChild.classList.remove(this.classNameInit);
                if (true === forceDisable) {
                    toggleChild.classList.remove(toggleClassName);
                } else {
                    const toggleState = toggleChild.classList.toggle(toggleClassName);
                    this.toggleDelayOn(toggleChild, toggleState, properties.toggleDelayOn);
                    this.toggleDelayOff(toggleChild, toggleState, properties.toggleDelayOff);
                }
            }
        });
    }

    /**
     * Keep a class with a time delay after the toggle class was removed.
     * Time delay is defined by attribute: `this.attributeDelayOff`
     *
     * @param {object}   toggle
     * @param {boolean}  toggleState
     * @param {int|null} delayTime
     */
    toggleDelayOff(toggle, toggleState, delayTime) {
        if (null !== delayTime) {
            if (false === toggleState) {
                var timerOff = setTimeout(() => {
                    toggle.classList.remove(this.classNameDelayOff);
                }, delayTime);
            } else {
                clearTimeout(timerOff);
                toggle.classList.add(this.classNameDelayOff);
            }
        }
    }

    /**
     * Add a class with a time delay after the toggle class was added.
     * Time delay is defined by attribute: `this.attributeDelayOff`
     *
     * @param {object}   toggle
     * @param {boolean}  toggleState
     * @param {int|null} delayTime
     */
    toggleDelayOn(toggle, toggleState, delayTime) {
        if (null !== delayTime) {
            if (true === toggleState) {
                var timerOn = setTimeout(() => {
                    toggle.classList.add(this.classNameDelayOn);
                }, delayTime);
            } else {
                clearTimeout(timerOn);
                toggle.classList.remove(this.classNameDelayOn);
            }
        }
    }

    /**
     * Remove toggle class by force.
     *
     * @param {object} disabler Disabler javascript node
     */
    toggleDisable(disabler) {
        const disablerValue = disabler.getAttribute(this.attributeDisable);
        switch (this.eventListenerType) {
            case 'scroll':
                this.scrollToggles.forEach(scrollToggle => {
                    if (scrollToggle.getAttribute(this.options.attribute) === disablerValue) this.toggleClickEvent(scrollToggle, true);
                });
                break;
            default:
                this.clickToggles.forEach(clickToggle => {
                    if (clickToggle.getAttribute(this.options.attribute) === disablerValue) this.toggleClickEvent(clickToggle, true);
                });
        }
    }

    /**
     * Event listener for disabler. Note: is always a click event!
     */
    eventListenerDisabler() {
        this.toggleDisablers.forEach(toggleDisabler => toggleDisabler.addEventListener('click', event => this.toggleDisable(event.currentTarget)));
    }
}
