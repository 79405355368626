module.exports = function (valuesToCheck, calledByMin) {
  let priceFrom = $('[data-minimum-price='.concat('"', valuesToCheck, '"]'));
  let priceTill = $('[data-maximum-price='.concat('"', valuesToCheck, '"]'));

  let min = parseInt(priceFrom.val());
  let max = parseInt(priceTill.val());

  if (min >= max) {
    if (true === calledByMin) {
      if (priceTill.find('option[value="' + min + '"]').length && priceTill.find('option[value="' + min + '"]').next().length) {
        priceTill.find('option').attr('selected', false).prop('selected', false);
        priceTill.find('option[value="' + min + '"]').next().attr('selected', 'selected').prop('selected', 'selected');
      }
    } else {
      if (priceFrom.find('option[value="' + max + '"]').length && priceFrom.find('option[value="' + max + '"]').prev().length) {
        priceFrom.find('option').attr('selected', false).prop('selected', false);
        priceFrom.find('option[value="' + max + '"]').prev().attr('selected', 'selected').prop('selected', 'selected');
      }
    }
  }
}
