import Vue from 'vue'
import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

Sentry.init({
    dsn: "https://61d46d88144646b7b14d536f722ede43@o361359.ingest.sentry.io/5250170",
    integrations: [
        new VueIntegration({
            Vue,
            attachProps: true,
        }),
    ],
    ignoreErrors:[
        "Non-Error promise rejection captured"
    ]
});
