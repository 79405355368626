/* eslint no-param-reassign: 0 */
// This ESLINT rule is needed because we use a recursive function to set
// form data to our variable.
// This is recaptcha v3

const $ = require('jquery');

class SymfonyForm {
  static bindEventListeners() {
    const $submit = $('[data-event-symfony-form]');
    const alertElement = $('[data-ajax-request]');
    const loading = $('[data-ajax-loading]');

    $submit.closest('form').on('RecaptchaSuccessValidated', () => {
      const $form = $submit.closest('form');
      const actionUrl = $form.attr('action');
      const formData = {};
      let formMethod = 'POST';

      $form.serializeArray().forEach((value) => {
        if (value.name.startsWith('_') === true) {
          // Do handle.
          switch (value.name) {
            case '_method':
              formMethod = value.value;
              break;

            case '_token':
              // eslint-disable-next-line no-underscore-dangle
              formData._token = value.value;
              break;

            default:
          }

          return undefined;
        }

        if (value.name === 'g-recaptcha-response') {
          formData["recaptcha"] = value.value;
        }

        const keys = value.name.split('[');
        if (keys.length <= 0) {
          return undefined;
        }

        this.insertValue(value.value, keys, 1, formData);

        return undefined;
      });


      loading.removeClass('d-none');
      alertElement.addClass('d-none');
      $form.find("div.invalid-feedback.help-block").remove();
      $form.find("div.form-group.has-error").removeClass('has-error');
      $form.find("div[data-event-delete]").remove();

      const oldBtnData = $submit.html();

      // set spinner
      $submit.css('min-width', $submit.innerWidth());
      $submit.css('min-height', $submit.innerHeight());
      $submit.html('<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>');
      $submit.attr('disabled', 'disabled');

      $.ajax({
        url: actionUrl,
        type: formMethod,
        contentType: 'application/json',
        data: JSON.stringify(formData),
        dataType: 'JSON',
        cache: false,
      }).fail((data) => {
        const jsonData = data.responseJSON.data;
        const failEvent = new CustomEvent('symfony-form-fail', {detail: data});
        $form.get(0).dispatchEvent(failEvent);

        for (const id in jsonData) {
          let message = jsonData[id];

          if ('object' === typeof message && message !== null) {
            message = message[""];
          }

          const $input = $form.find("[id$='" + id + "']");
          const $formGroup = $input.parent();
          const feedback = $('<div>');

          // Without ID, assume Class error.
          if (0 === $input.length) {
            // If we have no ID, assume it is a Class error.
            let $alert = $("<div>");
            $alert.addClass('alert').addClass('alert-danger').attr('role', 'alert');
            $alert.addClass('notification').addClass('error');
            $alert.attr('data-event-delete', '');
            $alert.html(message);
            $alert.prependTo($form);
          }

          feedback.addClass('invalid-feedback').addClass('help-block');
          feedback.html(message);
          $formGroup.addClass('has-error');

          feedback.appendTo($input.parent());
        }

        /**
         * Scroll to error
         */
        let $htmlBody = $('html, body'),
          $formError = $form.find('.form-group.has-error');

        if (0 < $formError.length) {
          // Scroll to from error.
          $htmlBody.animate({
            scrollTop: $formError.first().offset().top - (50 * 2) + 'px'
          }, 600);
        } else {
          let $formAlert = $form.find('.alert');
          if (0 < $formAlert.length) {
            // Scroll to form alert.
            $htmlBody.animate({
              scrollTop: $formAlert.first().offset().top - (50 * 2) + 'px'
            }, 600);
          }
        }
      }).done((data) => {
        const doneEvent = new CustomEvent('symfony-form-done', {detail: data});
        $form.get(0).dispatchEvent(doneEvent);
      }).always(() => {
        const alwaysEvent = new Event('symfony-form-always');
        $form.get(0).dispatchEvent(alwaysEvent);

        $submit.html(oldBtnData);
        $submit.removeAttr('disabled');
      });
    });
  }

  static insertValue(value, keys, offset, entry) {
    let key = keys[offset];
    if (key === undefined) {
      return undefined;
    }
    key = keys[offset].replace(']', '');

    if (entry[key] === undefined) {
      entry[key] = {};
    }

    if (offset === keys.length - 1) {
      entry[key] = value;

      return undefined;
    }

    const nextKey = keys[offset + 1].replace(']', '');

    if ('' === nextKey) {
      // Next key empty, we are in an array.
      if (entry[key] === undefined) {
        entry[key] = {0: value};
      } else {
        const nextNumb = Object.keys(entry[key]).length;
        entry[key] = {...entry[key], [nextNumb]: value};
      }

      // We set our array, we are done.
      return undefined;
    }

    return this.insertValue(value, keys, offset + 1, entry[key]);
  }
}

module.exports = SymfonyForm;
