/**
 * Class ScrollTo
 *
 * Scroll to element
 */
export default class Scroll {

  /**
   * Scroll Listener Checker
   *
   * @type {boolean}
   */
  static didScroll = false;

  /**
   * Scrollable element.
   * The scrollable element for all environments in all browsers is the body element except huurstunt in IOS, which is the window element.
   *
   * @type {HTMLElement|Window}
   */
  static scrollableElement = null;

  /**
   * Get Scrollable element
   *
   * @returns {HTMLElement|Window}
   */
  static getScrollableElement() {
    if (null === this.scrollableElement) {
      this.scrollableElement = window;
    }

    return this.scrollableElement;
  }

  /**
   * Scroll to position of element
   *
   * @param {Element} element element to
   * @param {Number}  yOffset y offset in pixels. Default is 0.
   * @param {String}  topOrBottom Scroll to top or bottom. Options: 'top' and 'bottom'. Default is 'top'
   */
  static toElement(element, yOffset = 0, topOrBottom = 'top') {
    const elemPosition = 'top' === topOrBottom
      ? element.getBoundingClientRect().top
      : element.getBoundingClientRect().bottom;

    this.getScrollableElement().scrollTo({
      top: elemPosition + this.scrollYPos() + yOffset,
      behavior: 'smooth',
    });
  }

  /**
   * scroll to top of window
   */
  static toTop() {
    this.getScrollableElement().scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  /**
   * Get scroll Y position
   */
  static scrollYPos() {
    return this.getScrollableElement().scrollY;
  }

  /**
   * Scroll listener with throttling for better performance
   *
   * Alternative is Intersection Observer.
   * However, Intersection Observer is not yet recommend because of bad support for older versions of Safari.
   *
   * @param {Function} customMethod   custom functionality when scrolling
   * @param {...*}     args           extra params for customMethod
   */
  static scrollListener(customMethod, ...args) {
    this.getScrollableElement().addEventListener('scroll', () => {
      this.didScroll = true;
    });

    setInterval(() => {
      if ( true === this.didScroll ) {
        this.didScroll = false;
        customMethod(this.scrollYPos(), ...args);
      }
    }, 250);
  }
}
