'use strict';
const levenshteinDistance = require('js-levenshtein');

module.exports = (function (string, checkArray, maxTypo) {
  let lev;
  let closest;
  let shortest = -1;
  for (let element of checkArray) {
    lev = levenshteinDistance(string, element);

    if (0 === lev) {
      closest = element;
      shortest = 0;
      break;
    }

    if (lev <= shortest || 0 > shortest) {
      closest = element;
      shortest = lev;
    }
  }
  if (maxTypo < shortest || 0 === shortest) {
    return string;
  } else {
    return closest;
  }
});
